<template>
  <div class="app-container">
    <div class="filter-container">
      <el-date-picker
        v-model="Timedate"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="changeDate"
        value-format="yyyy-MM-dd"
        class="filter-item"
      >
      </el-date-picker>
      <el-select
        v-model="listQuery.semester"
        placeholder="请选择学期"
        clearable
        class="filter-item"
      >
        <el-option label="第一学期" :value="1" />
        <el-option label="第二学期" :value="2" />
      </el-select>
      <el-select
        v-model="listQuery.teacher_id"
        placeholder="请选择教师"
        style="width: 150px"
        class="filter-item"
      >
        <el-option
          v-for="item in teachers"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-input
        v-model="listQuery.course_name"
        placeholder="请输入课程名称"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-button class="filter-item" type="primary" @click="getList">
        查找
      </el-button>
    </div>
    <Title :data="data" :isShow="false"></Title>
    <div class="title tip_box">
      未提交
      <div class="tip color_1"></div>
      已提交/待审核
      <div class="tip color_2"></div>
      已通过
      <div class="tip color_3"></div>
      已驳回
      <div class="tip color_4"></div>
      驳回次数:{{ reject_num }}
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="课程" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school_course ? scope.row.school_course.name : "" }}
        </template>
      </el-table-column>
      <el-table-column label="学期" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.semester == 1 ? "第一学期" : "第二学期" }}
        </template>
      </el-table-column>
      <el-table-column label="上课教师" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.teacher ? scope.row.teacher.name : "" }}
        </template>
      </el-table-column>

      <el-table-column label="教学设计" min-width="120" align="center">
        <template slot-scope="scope">
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[1] ||
                !scope.row.class_course_data[1].teaching_plan
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[1].teaching_plan[0]
                        .examine_status
                    ]
              "
            >
              授课计划
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[1] ||
                !scope.row.class_course_data[1].course_standard
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[1].course_standard[0]
                        .examine_status
                    ]
              "
            >
              课程标准
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[1] ||
                !scope.row.class_course_data[1].course_outline
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[1].course_outline[0]
                        .examine_status
                    ]
              "
            >
              课程大纲
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[1] ||
                !scope.row.class_course_data[1].teaching_calendar
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[1].teaching_calendar[0]
                        .examine_status
                    ]
              "
            >
              教学日历
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[1] ||
                !scope.row.class_course_data[1].lecture
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[1].lecture[0].examine_status
                    ]
              "
            >
              说课
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="课件资料" min-width="120" align="center">
        <template slot-scope="scope">
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[2] ||
                !scope.row.class_course_data[2].handout
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[2].handout[0].examine_status
                    ]
              "
            >
              讲义
              <span
                style="color: red"
                v-if="
                  scope.row.class_course_data == null ||
                  !scope.row.class_course_data[2] ||
                  !scope.row.class_course_data[2].handout ||
                  scope.row.class_course_data[2].handout[0].is_commit == 0
                "
              >
                (上周未提交)
              </span>
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[2] ||
                !scope.row.class_course_data[2].lesson_plan
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[2].lesson_plan[0]
                        .examine_status
                    ]
              "
            >
              教案
              <span
                style="color: red"
                v-if="
                  scope.row.class_course_data == null ||
                  !scope.row.class_course_data[2] ||
                  !scope.row.class_course_data[2].lesson_plan ||
                  scope.row.class_course_data[2].lesson_plan[0].is_commit == 0
                "
              >
                (上周未提交)
              </span>
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[2] ||
                !scope.row.class_course_data[2].video_audio
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[2].video_audio[0]
                        .examine_status
                    ]
              "
            >
              音频/视频
              <span
                style="color: red"
                v-if="
                  scope.row.class_course_data == null ||
                  !scope.row.class_course_data[2] ||
                  !scope.row.class_course_data[2].video_audio ||
                  scope.row.class_course_data[2].video_audio[0].is_commit == 0
                "
              >
                (上周未提交)
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="学生考试" min-width="120" align="center">
        <template slot-scope="scope">
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[3] ||
                !scope.row.class_course_data[3].excellent_homework
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[3].excellent_homework[0]
                        .examine_status
                    ]
              "
            >
              作业
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[3] ||
                !scope.row.class_course_data[3].excellent_answer
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[3].excellent_answer[0]
                        .examine_status
                    ]
              "
            >
              试卷
            </div>
          </div>
          <!-- <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[3] ||
                !scope.row.class_course_data[3].school_report
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[3].school_report[0]
                        .examine_status
                    ]
              "
            >
              成绩表
            </div>
          </div> -->
        </template>
      </el-table-column>
      <el-table-column label="学生反馈" min-width="120" align="center">
        <template slot-scope="scope">
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[4] ||
                !scope.row.class_course_data[4].teaching_quality_evaluation_form
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[4]
                        .teaching_quality_evaluation_form[0].examine_status
                    ]
              "
            >
              全度教学质量评价表
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="教师总结" min-width="110" align="center">
        <template slot-scope="scope">
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[5] ||
                !scope.row.class_course_data[5].teacher_summary
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[5].teacher_summary[0]
                        .examine_status
                    ]
              "
            >
              教师总结
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="教学评定" min-width="110" align="center">
        <template slot-scope="scope">
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[6] ||
                !scope.row.class_course_data[6]
                  .teaching_quality_evaluation_form_expert
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[6]
                        .teaching_quality_evaluation_form_expert[0]
                        .examine_status
                    ]
              "
            >
              全度教学质量评价表(专家专用)
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[6] ||
                !scope.row.class_course_data[6]
                  .teaching_quality_evaluation_form_teacher
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[6]
                        .teaching_quality_evaluation_form_teacher[0]
                        .examine_status
                    ]
              "
            >
              全度教学质量评价表(教师专用)
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="查课评价" min-width="110" align="center">
        <template slot-scope="scope">
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[7] ||
                !scope.row.class_course_data[7].student_evaluation_feedback_form
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[7]
                        .student_evaluation_feedback_form[0].examine_status
                    ]
              "
            >
              全度教学巡课-学生评价反馈表
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[7] ||
                !scope.row.class_course_data[7].class_record
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[7].class_record[0]
                        .examine_status
                    ]
              "
            >
              全度教学巡课-课堂情况记录表
            </div>
          </div>
          <div class="table_box">
            <div
              :class="
                scope.row.class_course_data == null ||
                !scope.row.class_course_data[7] ||
                !scope.row.class_course_data[7].total_score_of_class_tour
                  ? 'color_1'
                  : color[
                      scope.row.class_course_data[7]
                        .total_score_of_class_tour[0].examine_status
                    ]
              "
            >
              全度教学巡课-巡课评分总表
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleClassSchedule(scope.row)"
            >教学设计</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleCoursewareInfo(scope.row)"
            >课件资料</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handlerStudentExam(scope.row)"
            >学生考试</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleStudentScore(scope.row)"
            >学生成绩</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleStudentFeedback(scope.row)"
            >学生反馈</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleTeacherSummary(scope.row)"
            >教师总结</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleAssessment(scope.row)"
            >教学评定</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleCheckReviews(scope.row)"
            >查课评价</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="handleDownload(scope.row)"
          >
            一键下载资料
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import JSZip from "jszip";
import axios from "axios";
import fileSaver from "file-saver";
const getFile = (url) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      responseType: "arraybuffer",
    })
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error.toString());
      });
  });
};
export default {
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 10,
        type: 2,
        class_id: "",
        semester: null,
        start_at: "",
        end_at: "",
        teacher_id: "",
      },
      teachers: [],
      DownloadList: [],
      list: [],
      total: null,
      listLoading: false,
      Timedate: null,
      reject_num: null,
      form: {
        id: "",
      },
      data: {},
      color: ["", "color_2", "color_3", "color_4"],
    };
  },
  created() {
    this.data = JSON.parse(this.$route.query.data);
    this.listQuery.class_id = this.data.id;
    this.getList();
    this.getTeacherList();
  },
  methods: {
    forKey(row, tetx) {
      for (const key in row) {
        let li = row[key];
        li[0].data.forEach((item) => {
          this.DownloadList.push({
            url: item.url,
            name: tetx + "/" + item.name,
          });
        });
      }
    },
    handleDownload(row) {
      this.listLoading = true;
      const data = [];
      for (const key in row.class_course_data) {
        console.log(key);
        let item = row.class_course_data[key];
        if (key == 1) {
          this.forKey(item, "教学计划");
        } else if (key == 2) {
          this.forKey(item, "课件资料");
        } else if (key == 3) {
          this.forKey(item, "学生考试");
        } else if (key == 4) {
          this.forKey(item, "学生反馈");
        } else if (key == 5) {
          this.forKey(item, "教师总结");
        } else if (key == 6) {
          this.forKey(item, "教学评定");
        } else if (key == 7) {
          this.forKey(item, "查课评价");
        }
      }
      this.DownloadList.forEach((item) => {
        if (item.url) {
          let routeUrl = item.url.replace(/http:/, "https:");
          data.push({ url: routeUrl, name: item.name });
        }
      });
      // 需要下载打包的路径, 可以是本地相对路径, 也可以是跨域的全路径
      const zip = new JSZip();
      const cache = {};
      const promises = [];
      data.forEach((item) => {
        const promise = getFile(item.url).then((data) => {
          // 下载文件, 并存成ArrayBuffer对象
          // const arr_name = item.split("/");
          // const file_name = arr_name[arr_name.length - 1]; // 获取文件名
          const file_name = item.name; // 获取文件名
          zip.file(file_name, data, { binary: true }); // 逐个添加文件
          cache[file_name] = data;
        });
        promises.push(promise);
      });
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: "blob" }).then((content) => {
          this.listLoading = false;
          // 生成二进制流
          fileSaver.saveAs(
            content,
            `${this.data.college.school.name}${this.data.college.name}${this.data.major.name}${
              this.data.school_year
            }-${this.data.school_year - 0 + 1}年${this.data.name}--${ row.school_course.name}  教务资料.zip`
          ); // 利用file-saver保存文件
        });
      });
    },
    getTeacherList() {
      request({
        url: "/api/schoolend/teacher/list",
        method: "get",
        params: {
          limit: 99999999999999,
          not_status: 3,
        },
      }).then((response) => {
        this.teachers = response.data.data;
        console.log(this.teachers);
        // this.teachers.find(item=>item)
      });
    },
    changeDate() {
      console.log(this.Timedate);
      if (!this.Timedate || this.Timedate.length == 0) {
        return;
      }
      this.listQuery.start_at = this.Timedate[0];
      this.listQuery.end_at = this.Timedate[1];
    },
    dataup(row) {
      this.data.semester = row.semester;
      this.data.major.name = row.school_course.name;
      this.data.teacher = row.teacher.name;
      localStorage.setItem("classdata", JSON.stringify(this.data));
    },
    handleClassSchedule(row) {
      this.dataup(row);
      this.$router.push(
        `/educationin/instructionaldesign?type=1&class_course_id=${
          row.id
        }&data=${JSON.stringify(this.data)}`
      );
    },
    handleCoursewareInfo(row) {
      this.dataup(row);
      this.$router.push(
        `/educationin/coursewareinfo?type=2&class_course_id=${
          row.id
        }&data=${JSON.stringify(this.data)}`
      );
    },
    handleStudentScore(row) {
      this.dataup(row);
      this.$router.push(
        `/educationin/studentscore?type=3&class_course_id=${
          row.id
        }&data=${JSON.stringify(this.data)}`
      );
    },
    handlerStudentExam(row) {
      this.dataup(row);
      this.$router.push(
        `/educationin/studentexam?type=3&class_course_id=${
          row.id
        }&data=${JSON.stringify(this.data)}`
      );
    },

    handleStudentFeedback(row) {
      this.dataup(row);
      this.$router.push(
        `/educationin/studentfeedback?type=4&class_course_id=${
          row.id
        }&data=${JSON.stringify(this.data)}`
      );
    },
    handleTeacherSummary(row) {
      this.dataup(row);
      this.$router.push(
        `/educationin/teachersummary?type=5&class_course_id=${
          row.id
        }&data=${JSON.stringify(this.data)}`
      );
    },
    handleAssessment(row) {
      this.dataup(row);
      this.$router.push(
        `/educationin/assessment?type=6&class_course_id=${
          row.id
        }&data=${JSON.stringify(this.data)}`
      );
    },
    handleCheckReviews(row) {
      this.dataup(row);
      this.$router.push(
        `/educationin/checkreviews?type=7&class_course_id=${
          row.id
        }&data=${JSON.stringify(this.data)}`
      );
    },

    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/eduAdmin/courseList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.reject_num = response.data.reject_num;
        this.listLoading = false;
      });
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.title {
  margin-bottom: 10px;
}
.tip_box {
  display: flex;
  align-items: center;
  .tip {
    width: 20px;
    height: 20px;
    margin: 0 20px 0 5px;
  }
  .color_1 {
    background: black;
  }
  .color_2 {
    background: #11eeee;
  }
  .color_3 {
    background: #4db34d;
  }
  .color_4 {
    background: red;
  }
}
.tip {
  width: 20px;
  height: 20px;
  margin: 0 20px 0 5px;
}
.color_1 {
  // background: black;
  color: black;
}
.color_2 {
  // background: #11eeee;
  color: #11eeee;
}
.color_3 {
  // background: #4db34d;
  color: #4db34d;
}
.color_4 {
  // background: red;
  color: red;
}
.table_box {
  display: flex;
  justify-content: center;
}
</style>
